.login{
    background-color: white;
    height: 100vh;
}
.mui-selected{
    background-color: #1237BFB2 !important;
    color: white !important;
    border-radius: 50px !important;
}
.unselected{
    border: 0px !important;
}
.Buttongrp{
    
    border: #1237BF 1px solid;
    max-width: fit-content;
    justify-self: center !important;
    justify-content: center !important;
    align-items: center !important;
}


.wrapper_login_buttons{
   display: grid;
   grid-template-columns: 1fr;
   grid-gap: 2rem;
   margin-top: 1rem;
}
.signup-btn {
    border-radius: 8px;
    background: #1237BF;
    color: white;
  }

  .mobileImage{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display:none;
  }

@media (max-width: 800px) {
    .mobileImage{
        z-index: 0;
        object-fit: cover;
        display: block;
        filter: blur(5px);
        height: 100vh;
    }
}
  /* Media queries */
  @media (max-width: 600px) {
    .left-column {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: url('../Assets/imagesB/Boy.png') no-repeat center center;
      background-size: cover;
      filter: blur(8px);
      z-index: -1;
    }
    
.banner-illustration{
    /* top: 0;
    left: 0; */
    width: 100%;
    height: 100%;
    margin: 0 5px 0 5px !important;
    justify-self: center !important;
    /* position: absolute; */
    /* z-index: -1; */
    /* filter: blur(8px); */
}

.mobileImage{
    z-index: 0;
    object-fit: cover;
    display: block;
    filter: blur(5px);
    height: 100vh;
}
}
.form-fields{
    display:grid;
    grid-template-columns: repeat(1fr);
    place-items: center !important;
}
