.alert-container {
    position: fixed;
    top: 20px;
    right: 20px;
    z-index: 2000;
  }
  
  @keyframes slide-in-right {
    from {
      transform: translateX(100%);
      opacity: 0;
    }
    to {
      transform: translateX(0);
      opacity: 1;
    }
  }
  
  @keyframes slide-out-right {
    from {
      transform: translateX(0);
      opacity: 1;
    }
    to {
      transform: translateX(100%);
      opacity: 0;
    }
  }
  
  .slide-in {
    animation: slide-in-right 0.5s forwards;
  }
  
  .slide-out {
    animation: slide-out-right 0.5s forwards;
  }
  

.container{
  background-color: white;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  font-weight: bold;
  /* border: 1px solid black; */
}
.analytics-container{
  background-color: #B8C3EC;

}

.wrap-graphs{
  display: grid;
grid-template-columns: 1fr 1fr;
  align-items: center;
  font-size: 2rem;
  font-weight: bold;
}

/* Customize the icon color */
.swal-icon--success {
  color: blue;
}

/* Customize the title text color */



/* Mobile responsiveness for graphs */

@media (max-width: 1100px) {
  .wrap-graphs{
  grid-template-columns: 1fr !important; 
  }
}
@media (max-width: 400px) {
  .logo-text{
    display: none;
    }
}
@media (max-width: 600px) {
  .wrap-graphs{
  gap:1rem 0rem !important;
  }
}


/* //sparkline box css */
.spark_text{
  font-size: 1rem;
}