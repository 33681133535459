/* styles/DashboardLayout.css */
/* .dashboard-layout {
  background-color: #B8C3EC;

  } 
  
   .sidebar {
    position: fixed; 
    height: 90vh;
    background-color: white;
    width: 13rem;
    color: white;
    display: flex;
    border: 1px solid blue;
    border-bottom-right-radius: 20px;
    border-top-right-radius: 20px;
    
    z-index: 1000;
  } 
  
   .dashboard-main-content {
    flex-grow: 1;
    margin-left: 14rem; 
    width: calc(100% - 18);
    display: flex;
    flex-direction: column;
    
  } 
  
   .topbar {
    background-color: white;
    padding: 1rem;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  } 
  
   .dashboard-content {
    flex-grow: 1;
    padding: 1rem;
    background-color: white;
    height: calc(100vh - 10vh); 
    overflow-y: hidden;
  } 

  .nav {
    background-color: grey;
    align-items: start;
    padding-top: 1rem;
    
    
   
  }
  .nav-list {
    display: flex;
    list-style: none;
    
    flex-direction: column;
    gap: 1rem;
    
    
  }

  a {
    color: black;
    text-decoration: none;
   
   
  }
   */

/* styles/DashboardLayout.css */
@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap");

* {
  font-family: "Outfit", sans-serif;
}

.dashboard-layout {
  background-color: #b8c3ec;
  overflow-y: hidden;
  overflow-x: hidden;
  max-height: 100vh;
}

.dashboard-main-content {
  box-sizing: border-box;
  flex-grow: 1;
  margin-left: 15rem;

  width: calc(100% - 14rem);
  display: flex;
  flex-direction: column;
}

.dashboard-content {


  margin-right: 3rem;
  margin-top: 1rem;
  margin-bottom: 2rem;
  background-color: white;
  height: calc(100vh - 18vh);

  overflow-x: hidden;
  border-radius: 20px;
  box-sizing: border-box;

}
.remove-bg {
  background-color: transparent;
}

/* topbar ------------------------*/

.topbar {
  position: sticky;
  top: 0;
  background-color: white;
  padding: 1rem;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* -------------------------------------------------- */

/* sidebar ------------------------*/

.sidebar {
  position: fixed;
  height: 90vh;
  background-color: white;
  width: 14rem;
  color: white;
  display: flex;
  /* border: 1px solid blue; */
  border-bottom-right-radius: 20px;
  border-top-right-radius: 20px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.nav {
  position: fixed;
  z-index: 1;
  align-items: start;
  padding-top: 1rem;
  margin-left: 0;
}

.nav-list {
  display: flex;
  list-style: none;
  flex-direction: column;
  gap: 2rem;
}

a {
  color: black;
  text-decoration: none;
  font-weight: bolder;
  font-size: 1rem;
}

.sidebar-content {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}

img {
  max-width: 100%;
  height: auto;
}

.sidebar-content img {
  width: 1rem;
}
/* -------------------------------------------------- */

/* mobile responsiveness */

@media (max-width: 600px) {
  .dashboard-main-content {
    margin-left: 0;
    width: 100%;
  }

  .dashboard-content {
    width: 100%;
    margin: 0;
    padding: 1rem;
    padding-bottom: 0;
    border-radius: 0;
    height: 90vh;
  }

  .tableTitle {
    display: none;
  }
}
