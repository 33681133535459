
@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap');

.header-wrapper{
  display: grid;
  margin: 0;
  padding: 0;
  width: 100%;
}


.header {
  font-family: "Outfit", sans-serif;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white; 
  padding: 10px 20px; 
  height: 5rem;
  border-bottom: .5px solid #1237BF;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  width: 100%;
  margin: 0;
  padding: 0;
  padding-left: 4rem;
  padding-right: 4rem;
}

.logo {
  
  display: flex;
  align-items: center;
}

.header-nav {
  margin-left: 2rem;
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 1rem;
}

.nav-list-header {
  display: flex;
  list-style: none;
}

.header-nav-item {
  
  margin: 0 10px;
  color: #000;
  font-size: 49px;
  font-weight: 349;
  font-style: normal;
  line-height: 150%;
  cursor:pointer
 
}

.auth-buttons {
  display: flex;
  align-items: center;
}

.btn {
  margin-left: 10px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
}

.signup-btn {
  border-radius: 8px;
  background: #1237BF;
  color: white;
  font-weight: 350;
  padding: .3rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-bottom:.4rem ;
  padding-top:.4rem ;
}

.login-btn {
  border: 2px solid #1237BF;
  background: #FFF;
  color: #1237BF;
  font-weight: 350;
  padding: .3rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.menu-button {
  display: none;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

.mobile-nav {
  display: none;
  flex-direction: column;
  align-items: center;
  background-color: white;
  position: absolute;
  top: 5rem;
  width: 100%;
  z-index: 10;
  padding: 10px
}

.mobile-nav.active {
  display: flex;
}

.nav-list-mobile {
  display: flex;
  flex-direction: column;
  align-items: center;
  color:#1237BF;
  list-style: none;
  padding: 0;
  width: 100%; 
}

.nav-list-mobile li {
  color:#1237BF;
  border-bottom: 1px solid #F9A33F;
  width: 100%; 
  text-align: center; 
}

.nav-list-mobile .header-nav-item {
  margin: 10px 0;
}

@media (max-width: 600px) {
  .header {
    justify-content: space-between;
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .logo {
    display: none;
  }

  .header-nav {
    display: none;
  }

  .menu-button {
    display: block;
  }

  .auth-buttons {
    margin: 0;
    padding: 10px 0;
    justify-content: flex-end;
  }

  .btn {
    margin: 5px;
    width: auto;
  }

  .mobile-nav {
    display: none;
  }

  .mobile-nav.active {
    display: flex;
  }
}
