/* .sidebar{
    height: 90vh;
    background-color: white;
    width: 13rem;
    color: white;
    display: flex;
    border: 1px solid blue;
    border-bottom-right-radius: 20px;
    border-top-right-radius: 20px;
    
    z-index: 1000;
   
} */
/* .nav {
    flex-grow: 1;
    display: flex;
    justify-content: center; 
    flex-direction: column;
   
  }
  
  .nav-list {
    display: flex;
    list-style: none;
    width:20px;
    margin: 0;
    padding: 0;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
    
  }
  
  .nav-item {
    margin: 0;
    text-align-last: left;

  }
  a {
    color: black;
    text-decoration: none;
    text-align-last: left;
   
  }  */

/* responsive css */

@media (max-width: 600px) {
  .sidebar {
    z-index: 99;
    /* display: none !important; */
    height: 52vh !important;
    width: 100% !important;
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    display: flex;
    justify-content: center;
  }
  .sidebar_text {
    font-size: 1rem;
  }
  
  .nav-list{
    padding: initial;
  }
}
.sidebar-content .active-link {
  color: blue;
  font-weight: bold;
}
.sidebar-content .active-link span {
  position: relative !important; /* Ensure relative positioning for the pseudo-element */
  /* font-size: 1.3rem; */
}
.sidebar-content a {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.2rem;
}
.sidebar-content .active-link span::after {
  content: "";
  position: absolute;
  bottom: -4px; /* Adjust as needed */
  left: 0;
  width: 50%; /* Adjust the width of the underline */
  height: 4px; /* Thickness of the underline */
  background-color: #f9a33f; /* Solid color for the underline */
  border-radius: 15px; /* Rounded border radius */
}
.sidebar_text {
  font-size: 20px;
}
.sidebar-content .sidebar-icons {
  width: 26px; /* Adjust size as needed */
  height: 26px; /* Adjust size as needed */
}

.sidebar-content span {
  font-size: 21px; /* Adjust size as needed */
  font-weight: bold; /* Optional: make the text bold */
  margin-left: 10px;
}
